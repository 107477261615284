import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { tap, finalize } from 'rxjs/operators';
import { LoaderService } from "../services/loader.service";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { API_CONFIG_TOKEN } from '../token';
import { ApiConfig } from '../model/apiConfig';

@Injectable({
  providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor {

  public hostURL=environment.hostURL;
  public apiURL=environment.apiURL;
  constructor(public router: Router,public loaderService:LoaderService, @Inject(API_CONFIG_TOKEN) private readonly apiConfig:ApiConfig) {}  

  token:string='';

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //show the loader when the intercept function is called
  this.loaderService.show();
  //if useApifApi==false then add header X-CSRF-TOKEN to POST method 
  if(this.apiConfig.useApifApi===false){
    if(this.token && req.method==="POST"){
      req=req.clone({headers:req.headers.append('X-CSRF-TOKEN',this.token)});
    }
  }

  //add a header for apif-flag and fdm_opt_flag in http calls
  req=req.clone({headers:req.headers.append('APIF-FLAG',this.apiConfig.useApifApi.toString())});
  return next.handle(req)
  .pipe(
      //if api call succeeds or fails, hide the loader
      finalize(()=>this.loaderService.hide()),
      tap(event => {
        if(this.apiConfig.useApifApi===false){
          if (event instanceof HttpResponse) {
            if(req.url.includes('/getToken')){
            this.token=event.body.XCSRFTOKEN;
          }
        }
      }
    },
    /*if getPreference or updatePreference throws a status code 
      400 || 401 || 402 (user not authenticated) redirect user to privacy page*/
    (error:any) => {
        if(error instanceof HttpErrorResponse){
          if(error.status === 400 || error.status === 401 || error.status === 402){
            window.location.href=`${this.hostURL}/en-us/delivery-manager/privacy.html`; 
          }
        } 
      })
    )
  } 
}


