import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalConstants } from '../global-constants';
import { environment } from 'src/environments/environment';
import { API_CONFIG_TOKEN } from '../token';
import { ApiConfig } from '../model/apiConfig';
import { optoutGet } from 'src/app/model/optout.model';
import { optoutPost } from 'src/app/model/optout.model';
import { mergeMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class OptoutService {

  apiURL=environment.apiURL;

  getPreferenceApi=GlobalConstants.getPreferenceEndpoint;
  updatePreferenceApi=GlobalConstants.updatePreferenceEndpoint;
  getTokenApi=GlobalConstants.getTokenEndpoint;
  getPreferenceApifApi=GlobalConstants.getPreferenceApifEndpoint;
  updatePreferenceApifApi=GlobalConstants.updatePreferenceApifEndpoint;

  constructor(@Inject(API_CONFIG_TOKEN) private readonly apiConfig:ApiConfig, private httpClient:HttpClient, private cookieService:CookieService) {}    

  /*fetch the user opted in or opted out details from back-end service */
  deliveryManagerInquiryResponse():Observable<optoutGet>{
    // if(this.apiConfig.useApifApi===false){
    //   return this.httpClient.get<any>(`${this.apiURL}/${this.getTokenApi}`).pipe(
    //     mergeMap(token=>this.httpClient.get<optoutGet>(`${this.apiURL}/${this.getPreferenceApi}`))
    //   )
    // }
    // else{
    //   return this.httpClient.get<optoutGet>(`${this.apiURL}/${this.getPreferenceApifApi}`)
    // }
    return this.httpClient.get<any>(`${this.apiURL}/${this.getTokenApi}`).pipe(
      mergeMap(token=>this.httpClient.get<optoutGet>(`${this.apiURL}/${this.getPreferenceApi}`))
    )
  }

  /*posts the user opted details in the back-end service */
  deliveryManagerMaintenanceRequest(data:optoutPost):Observable<optoutPost>{
    // if(this.apiConfig.useApifApi===false){
    //   return this.httpClient.post<optoutPost>(`${this.apiURL}/${this.updatePreferenceApi}`,data)
    // }
    // else{
    //   return this.httpClient.post<optoutPost>(`${this.apiURL}/${this.updatePreferenceApifApi}`,data)
    // }
    return this.httpClient.post<optoutPost>(`${this.apiURL}/${this.updatePreferenceApi}`,data)
  }
}
